import {collection, doc, getFirestore,setDoc, getDocs, getDoc} from "firebase/firestore";
import {getUser} from "../db/auth"

const db = (typeof window !== 'undefined') ? getFirestore() : null;

export async function checkUserExistence()
{
    const colRef = await collection(db,`users/${getUser().uid}`);
}

export async function instantiateUser(country, state, city, school, qwiklabs_url, developerProfile)
{
    const userRef = await setDoc
    (
        doc(db,"users",getUser().uid),
        { // data payload we want to save
            email: getUser().email,
            name: getUser().displayName,
            country: country,
            state: state, 
            city: city,
            school: school,
            //photoURL: getProfilePicture(),// random img
            qwiklabsUrl: qwiklabs_url,
            googleDevsUrl: developerProfile,
            googleDevBadgeCount: 0,
            qwiklabsBadgeCount: 0
        }
    );
    console.log(userRef);
}

export async function getSchoolsFromCountry(country) 
{
    return await getDocs(collection(db,`universities/${country}$/schools`));
}

export async function getUserData() 
{
    const docRef = doc(db, "users", getUser().uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
    return null;
    }
}

export async function getDevelopmentTeam()
{
    return await getDocs(collection(db,`development_team`));
}