import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import { Link } from "gatsby";
import { useState } from "react";
import {instantiateUser} from "../db/firestore"


import Logo from "../assets/images/logo.png";

const SignUp = () => 
{
    const [country, setCountry] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [qwiklabsProfile, setQwiklabsProfile] = useState("")
    const [developerProfile, setDeveloperProfile] = useState("")
    return (
    <Layout>
        <SEO title="Register" /> 

        <Navbar />

        <PageBanner pageTitle="Your information" />

        <div className="ptb-80">
            <div className="container">
                <div className="auth-form">
                    <div className="auth-head">
                        <Link to="/home">
                            <img src={Logo} />
                        </Link>
                    </div>

                    <form>
                        <div className="mb-3">
                            <label className="form-label">Country</label>
                            <select name="Country" className="form-control" id="country" value = {country} onInput={(e)=>setCountry(e.target.value)}>
                                <option>country 1</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">State/Province</label>
                            <select name="state" className="form-control" id="state" value = {state} onInput={(e)=>setState(e.target.value)}>
                                <option>state 1</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">City</label>
                            <select name="City" className="form-control" id="city" value = {city} onInput={(e)=>setCity(e.target.value)}>
                                <option>city 1</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">School Name</label>
                            <select name="School" className="form-control" id="school" value = {schoolName} onInput={(e)=>setSchoolName(e.target.value)}>
                                <option>school name 1</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">QwikLabs Profile Link</label>
                            <input type="text" className="form-control" id="qwiklabsProfile" value = {qwiklabsProfile} onInput={(e)=>setQwiklabsProfile(e.target.value)} required/>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Google Developers Profile Link (make one at <a href="https://developers.google.com/" target="_blank" >here</a>)</label>
                            <input type="text" className="form-control" id="developerProfile" value = {developerProfile} onInput={(e)=>setDeveloperProfile(e.target.value)} required/>
                        </div>

                        <button type="submit" className="btn btn-primary" onClick={(e)=>instantiateUser(country, state, city, schoolName, qwiklabsProfile, developerProfile)} >Sign Up</button>
                    </form>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)
    }

export default SignUp;